.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    padding: 16px;
    gap: 24px;
    margin-bottom: 12px;
    border-radius: 12px;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1px 0px 0px rgba(204, 204, 204, 0.50) inset, 0px 1px 0px 0px rgba(26, 26, 26, 0.07);
}

.texts {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
}

.texts h2 {
    color: var(--black-default);
    line-height: 24px;
    font-weight: 600;
    text-align: center;
}

.texts p {
    color: var(--black-default);
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.texts p:last-child {
    font-size: 14px;
}

.div_field {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.div_field label {
    margin-bottom: 4px;
    color: var(--black-default);
    line-height: 20px;
    font-size: 13px;
    font-weight: 500;
}

.textfield {
    display: flex;
    width: 100%;
}

.input {
    width: -webkit-fill-available;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 13px;
    line-height: 16px;
    border: none;
    height: 46px;
    box-shadow: 
        inset 0 1px 0 0 #E3E3E3, 
        inset 1px 0 0 0 #E3E3E3, 
        inset -1px 0 0 0 #E3E3E3,
        inset 0 -1px 0 0 #B5B5B5;
}

.firstfield {
    margin-bottom: 16px !important;
}

.button {
    background-color: var(--black-button);
    color: var(--white-100);
    width: 100%;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    height: 46px;
    overflow: hidden;
    box-shadow: 
        0 1px 0 0 #E3E3E3,
        1px 0 0 0 #E3E3E3,
        -1px 0 0 0 #E3E3E3,
        0 -1px 0 0 #B5B5B5;
}

.button:hover {
    cursor: pointer;
    transition: 0.2s;  
    border: 2px solid rgba(255, 255, 255, 0.01);
}

.message {
    margin-top: 12px;
    background-color: #d72c0d1a;
    display: flex;
    align-items: center !important;
    border-radius: 8px;
    padding: 8px 16px;
    width: calc(100% - 32px);
}

.message p {
    font-size: 12px;
    margin-left: 12px;
    margin-bottom: 0px;
    color: #D72C0D;
}