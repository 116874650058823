.back {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-left: 48px;
    margin-top: 16px;
    gap: 8px;
}

.box {
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 16px;
    gap: 24px;
    border-radius: 12px;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1px 0px 0px rgba(204, 204, 204, 0.50) inset, 0px 1px 0px 0px rgba(26, 26, 26, 0.07);
    background: #FFFFFF;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.infoBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8px;
}

.infoBox h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}

.infoBox h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.infoBox p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.postCode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    width: 100%;
}

.postCode p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
}

.inputBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.buttonCopy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 46px;
    background-color: #2F2F2F;
    color: var(--white-100);
    padding: 6px 12px;
    border-radius: 8px;
    cursor: pointer;
}

.buttonCopy p {
    font-size: 12px;
}

.buttonCopy button {
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    height: 46px;
}

.instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    text-align: center;
    padding: 16px;
}

.instructions h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.instructionsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.instructionsList p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.loading {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin-bottom: 36px; */
    height: calc(100vh - 180px);
}

.loading p {
    margin-top: 24px;
    color: var(--black-default);
    font-size: 16px;
}

.return {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey-100);
    border-radius: 4px;
    padding: 16px;
    gap: 8px;

}

.message {
    margin-top: 12px;
    background-color: #d72c0d1a;
    display: flex;
    align-items: center !important;
    border-radius: 8px;
    padding: 8px 16px;
    width: calc(100% - 32px);
}

.message p {
    font-size: 12px;
    margin-left: 12px;
    margin-bottom: 0px;
    color: #D72C0D;
}

.button {
    background-color: var(--black-button);
    cursor: pointer;
    color: var(--white-100);
    width: 100%;
    padding: 6px 12px;
    border-radius: 8px;
    margin-top: 16px;
    font-size: 12px;
    line-height: 16px;
    height: 46px;
    overflow: hidden;
    box-shadow: 
        0 1px 0 0 #E3E3E3,
        1px 0 0 0 #E3E3E3,
        -1px 0 0 0 #E3E3E3,
        0 -1px 0 0 #B5B5B5;
}

.input {
    width: -webkit-fill-available;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 13px;
    line-height: 16px;
    border: none;
    height: 46px;
    box-shadow: 
        inset 0 1px 0 0 #E3E3E3, 
        inset 1px 0 0 0 #E3E3E3, 
        inset -1px 0 0 0 #E3E3E3,
        inset 0 -1px 0 0 #B5B5B5;
}

.standardSelect {
    border-radius: 8px;
    padding: 6px 12px;
    width: -webkit-fill-available;
    line-height: 16px;
    font-size: 13px;
    border: none;
    height: 58px;
    box-shadow: 0px -1px 0px 0px #B5B5B5 inset, -1px 0px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, 0px 1px 0px 0px #E3E3E3 inset;
}

.label {
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
}