.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
}

.div {
    display: flex;
    align-items: center;
}

.div p {
    color: var(--black-100);
    font-size: 12px;
}

.logo {
    width: 110px;
    padding: 10px 0px 10px 10px;
}

.link {
    font-size: 12px !important;
    color: var(--black-100) !important;
}
