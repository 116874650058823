.back {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-left: 48px;
    margin-top: 16px;
    gap: 8px;
}

.container {
    display: flex;
    align-items: center;
    gap: 16px;
    align-items: flex-start;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    padding: 16px;
    border-radius: 12px;
    gap: 24px;
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1px 0px 0px rgba(204, 204, 204, 0.50) inset, 0px 1px 0px 0px rgba(26, 26, 26, 0.07);
}

.texts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
}

.texts h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: var(--black-default)
}

.texts p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--black-default)
}

.formControl {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.formControl p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: var(--black-default)
}

select {
    background-color: transparent;
}

.select {
    border-radius: 8px;
    padding: 6px 12px;
    width: -webkit-fill-available;
    line-height: 16px;
    border: none;
    height: 46px;
    box-shadow: 0px -1px 0px 0px #B5B5B5 inset, -1px 0px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, 0px 1px 0px 0px #E3E3E3 inset;
}

.input {
    width: -webkit-fill-available;
    padding: 0px 12px;
    border-radius: 8px;
    font-size: 13px;
    line-height: 16px;
    border: none;
    height: 46px;
    box-shadow: 
        inset 0 1px 0 0 #E3E3E3, 
        inset 1px 0 0 0 #E3E3E3, 
        inset -1px 0 0 0 #E3E3E3,
        inset 0 -1px 0 0 #B5B5B5;
}

.button {
    background-color: var(--black-button);
    color: var(--white-100);
    width: 100%;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    height: 46px;
    overflow: hidden;
    box-shadow: 
        0 1px 0 0 #E3E3E3,
        1px 0 0 0 #E3E3E3,
        -1px 0 0 0 #E3E3E3,
        0 -1px 0 0 #B5B5B5;
}

.button:hover {
    cursor: pointer;
    transition: 0.2s;  
    border: 2px solid rgba(255, 255, 255, 0.01);
}

.boxResume {
    text-align: left;
    margin-top: 12px;
    margin-left: 12px;
}

.boxResume h2 {
    margin-bottom: 12px;
    text-align: center;
}

.boxResume p {
    margin-bottom: 4px;
}

.resume {
    display: flex;
    flex-direction: column;
}

.item {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 4px;
}

.item img {
    width: 75px;
    height: 82px;
    padding-right: 20px;
}

.creditMessage {
    display: flex;
    width: 100%;
    padding-left: 16px;
}

.creditMessage p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
}

.terms {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.termsText p, a {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: var(--black-default)
}

.message {
    margin-top: 12px;
    background-color: #d72c0d1a;
    display: flex;
    align-items: center !important;
    border-radius: 8px;
    padding: 8px 16px;
    width: calc(100% - 32px);
}

.message p {
    font-size: 12px;
    margin-left: 12px;
    margin-bottom: 0px;
    color: #D72C0D;
}


input[type=number]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    
}
input[type=number] { 
   -moz-appearance: textfield;
   appearance: textfield;

}

@media (width < 1024px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .boxResume {
        width: 500px;
    }
}
