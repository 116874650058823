.back {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-left: 48px;
    margin-top: 16px;
    gap: 8px;
}

.back p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.box {
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 16px;
    gap: 24px;
    border-radius: 12px;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1px 0px 0px rgba(204, 204, 204, 0.50) inset, 0px 1px 0px 0px rgba(26, 26, 26, 0.07);
    background: #FFFFFF;
}

.texts {
    width: 100%;
    text-align: center;
    color: var(--black-default);
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.texts h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.texts p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.description p {
    font-size: 14px;
    line-height: 20px;
}

.select {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    width: 100%;
}

.option {
    display: flex;
    flex-direction: column;
    background-color: var(--white-100);
    border-radius: 8px;
    align-items: center;
    padding: 8px;
    gap: 16px;
    border: 1px solid #E3E3E3;
}

.img {
    flex-shrink: 0;
}

.img img {
    width: 60px;
    height: 72px;
}

.productInfo {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    flex-grow: 1;
}

.productInfo p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.productInfo p:last-child {
    font-weight: 600;
    line-height: 24px;
}

.chip {
    background-color: var(--black-button);
    color: var(--white-100);
    box-shadow: 
        0 1px 0 0 #E3E3E3,
        1px 0 0 0 #E3E3E3,
        -1px 0 0 0 #E3E3E3,
        0 -1px 0 0 #B5B5B5;
}

.additional {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    gap: 16px;
    width: 100%;
}

.motive {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.motive p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: var(--black-default);
}

.change {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.change p {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: var(--black-default);
}

.changeSelect {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.selectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.standardSelect {
    border-radius: 8px;
    padding: 6px 12px;
    width: -webkit-fill-available;
    line-height: 16px;
    border: none;
    height: 46px;
    box-shadow: 0px -1px 0px 0px #B5B5B5 inset, -1px 0px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, 0px 1px 0px 0px #E3E3E3 inset;
}

.products {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.item {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.item svg {
    color: #FFF;
}

@media (max-width: 580px) {
    .item {
        flex-direction: column;
        text-align: center;
    }
}

option[disabled] {
    color: gray;
}

.excludeButton {
    cursor: pointer;
    color: var(--black-default);
    border-radius: 4px;
    padding: 14px;
}

.action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    flex-shrink: 0;
}

.actionSelect {
    border-radius: 8px;
    padding: 6px 12px;
    width: -webkit-fill-available;
    line-height: 16px;
    border: none;
    height: 46px;
    text-decoration: underline;
    /* width: 100%;
    min-width: 160px;
    max-width: 160px; */
}

select {
    background-color: transparent;
}

option {
    background-color: #FFFFFF;
    color: #000000;
    padding: 8px !important;
    width: 100%;
}

.checkBoxComments {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.helperText p {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--black-default);
}

.options {
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: center;
}

.divgeral {
    gap: 16px;
    display: flex;
    width: 100%;
}

.explication {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.explication p, label {
    font-size: 13px;
    line-height: 24px;
    color: var(--black-default);
}

.explication p {
    font-weight: 500;
}

.explication label {
    font-weight: 400;
}

.labelCheck {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--black-default);
}

.quantity {
    margin-right: 16px !important;
}

.miniButton {
    background-color: var(--green-100);
    color: var(--white-100);
    width: 120px;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #BABFC3;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 8px;
}

.button {
    background-color: var(--black-button);
    color: var(--white-100);
    width: 100%;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    height: 46px;
    overflow: hidden;
    box-shadow: 
        0 1px 0 0 #E3E3E3,
        1px 0 0 0 #E3E3E3,
        -1px 0 0 0 #E3E3E3,
        0 -1px 0 0 #B5B5B5;
}
.button:hover {
    cursor: pointer;
    transition: 0.2s;  
    border: 2px solid rgba(255, 255, 255, 0.01);
}

.newProduct {
    display: flex;
    width: 100%;
}

.div_error {
    text-align: center;
}

.error {
    margin-top: 24px;
}

.loading {
    color: var(--green-100);
    overflow: hidden;
}

.message {
    margin-top: 12px;
    background-color: #d72c0d1a;
    display: flex;
    align-items: center !important;
    border-radius: 8px;
    padding: 8px 16px;
    width: calc(100% - 32px);
}

.message p {
    font-size: 12px;
    margin-left: 12px;
    margin-bottom: 0px;
    color: #D72C0D;
}






