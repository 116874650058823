:root {

  --degrade: linear-gradient(to right, #FFBC28, #F23A3A);

  --black-default: #303030;;

  --disable-back: #F1F1F1;
  --disable-text: #8C9196;
  --disable-icon: #6D7175;

  --grey-50: #F3F3F3;
  --grey-75: #BABFC3;
  --grey-100: #6D7175;
  --grey-200: #575959;

  --green-100: #008060;
  --green-200: #006E52;
  --green-300: #005E46;
  --green-400: #003D2C;


  --white-100: #FFFFFF;
  --white-200: #EDEDED;
  --white-300: #D9D9D9;
  --white-400: #A8A8A8;

  --black-button: #2F2F2F;
}


* {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

