.back {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-left: 48px;
    margin-top: 16px;
    gap: 8px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    padding: 16px;
    border-radius: 12px;
    gap: 24px;
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1px 0px 0px rgba(204, 204, 204, 0.50) inset, 0px 1px 0px 0px rgba(26, 26, 26, 0.07);
}

.box p {
    color: var(--black-default);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.boxText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 24px;
}

.price {
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    color: var(--black-default);
}

.texts {
    text-align: center;
}

.buttons {
    margin-bottom: 10px;
}

.button {
    background-color: #2F2F2F;
    color: var(--white-100);
    width: 100%;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    border-radius: 8px;
    height: 46px;
    box-shadow: 0px -1px 0px 0px #B5B5B5 inset, -1px 0px 0px 0px #E3E3E3 inset, 1px 0px 0px 0px #E3E3E3 inset, 0px 1px 0px 0px #E3E3E3 inset;
}

.button:hover {
    cursor: pointer;
}

.decline {
    height: 46px;
    background-color: var(--white-100);
    color: var(--black-default);
    width: 100%;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    border-radius: 8px;
    box-shadow: 0px 0px 3.2px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #2F2F2F;


}

.decline:hover {
    cursor: pointer;
    border: 1px solid var(--black-default);
    transition: 0.2s;
}

.boxResume {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 16px;
    gap: 16px;
    width: 400px;
    /* height: 268px; */
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1px 0px 0px rgba(204, 204, 204, 0.50) inset, 0px 1px 0px 0px rgba(26, 26, 26, 0.07);
}

.titleResume {
    text-align: center;
}

.titleResume p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--black-default);
}

.basicResume {
    display: flex;
    gap: 16px;
}

.basicResume img {
    width: 60px;
    height: 72px;
}

.basicResume p {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--black-default);
}

.priceResume {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.priceResume p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: var(--black-default);
}

.value p,
.bonus p {
    font-weight: 600;
}

.item {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.bonusContainer, .priceContainer, .actionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bonus {
    display: flex;
    align-items: center;
    gap: 16px;
}

@media (width < 1024px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .boxResume {
        max-width: 500px;
    }
}
